import { createRouter, createWebHashHistory } from "vue-router";
// import store from "../store";

// function guardMyroute(to, from, next) {
//   const isAuthenticated = store.getters["auth/authentication"];
//   // console.log("inside router.js");
//   // console.log(isAuthenticated);

//   if (isAuthenticated) {
//     next(); // allow to enter route
//   } else {
//     next("/"); // go to '/login';
//   }
// }

const routes = [
  {
    path: "/:lang?/:display?",
    name: "home",
    meta: { layout: "home" },
    component: () => import("../Pages/Home/HomePage.vue"),
  },
  {
    path: "/:lang?/pagebuilder",
    name: "pagebuilder",
    // meta: { layout: "home" },
    component: () => import("../Pages/PageBuilder.vue"),
  },
  {
    path: "/:lang?/search_shop/:display?",
    name: "search_shop",
    // meta: { layout: "home" },
    component: () => import("../Pages/SearchShop/SearchShop.vue"),
  },
  {
    path: "/:lang?/stores",
    name: "stores",
    // meta: { layout: "home" },
    component: () => import("../Pages/Stores/StoresPage.vue"),
  },
  {
    path: "/:lang?/products",
    name: "products",
    // meta: { layout: "home" },
    component: () => import("../Pages/ProductsList/ProductsPage.vue"),
  },
  {
    path: "/:lang?/search-results",
    name: "search-results",
    // meta: { layout: "home" },
    component: () => import("../Pages/SearchResults/SearchResultsPage.vue"),
  },

  {
    path: "/:lang?/categories",
    name: "categories",
    // meta: { layout: "home" },
    component: () => import("../Pages/Categories/CategoriesList.vue"),
  },
  {
    path: "/:lang?/promotions/:display?",
    name: "promotions",
    // meta: { layout: "home" },
    component: () => import("../Pages/Promotions_Offers/PromotionsList.vue"),
  },
  {
    path: "/:lang?/promotions-details",
    name: "promotions-details",
    // meta: { layout: "home" },
    component: () => import("../Pages/Promotions_Offers/PromotionsDetails.vue"),
  },

  {
    path: "/:lang?/events/:display?",
    name: "events",
    // meta: { layout: "home" },
    component: () => import("../Pages/Events/EventsList.vue"),
  },
  {
    path: "/:lang?/events-details",
    name: "events-details",
    // meta: { layout: "home" },
    component: () => import("../Pages/Events/EventsDetails.vue"),
  },
  {
    path: "/:lang?/feedback",
    name: "feedback",
    // meta: { layout: "home" },
    component: () => import("../Pages/FeedbackForm.vue"),
  },
  {
    path: "/:lang?/chat-with-us",
    name: "chat-with-us",
    // meta: { layout: "home" },
    component: () => import("../Pages/ChatWithUs.vue"),
  },

  {
    path: "/:lang?/leasing",
    name: "leasing",
    // meta: { layout: "home" },
    component: () => import("../Pages/ShopLeasing.vue"),
  },
  {
    path: "/:lang?/getting_here",
    name: "gettinghere",
    // meta: { layout: "home" },
    component: () => import("../Pages/GettingHere.vue"),
  },
  {
    path: "/:lang?/careers/:display?",
    name: "careers",
    // meta: { layout: "home" },
    component: () => import("../Pages/Careers/CareersPage.vue"),
  },
  {
    path: "/:lang?/services",
    name: "services",
    // meta: { layout: "home" },
    component: () => import("../Pages/Services/ServicesPage.vue"),
  },

  // User Pages Start

  {
    path: "/:lang?/login",
    name: "login",
    // meta: { layout: "home" },
    component: () => import("../Pages/UserPages/LoginPage.vue"),
  },
  {
    path: "/:lang?/forgot_password",
    name: "forgot_password",
    // meta: { layout: "home" },
    component: () => import("../Pages/UserPages/ForgotPassword.vue"),
  },
  {
    path: "/:lang?/set_password",
    name: "set_password",
    // meta: { layout: "home" },
    component: () => import("../Pages/UserPages/SetPassword.vue"),
  },
  {
    path: "/:lang?/reset_password/:phone?",
    name: "reset_password_form",
    // meta: { layout: "home" },
    component: () => import("../Pages/UserPages/ResetPasswordForm.vue"),
  },
  {
    path: "/:lang?/register_user",
    name: "register_user",
    // meta: { layout: "home" },
    component: () => import("../Pages/UserPages/RegisterUser.vue"),
  },
  {
    path: "/:lang?/register",
    name: "register",
    // meta: { layout: "home" },
    component: () => import("../Pages/UserPages/OtpValidation.vue"),
  },
  {
    name: "reset_password",
    path: "/:lang?/reset_password",
    component: () => import("../Pages/UserPages/ResetPassword.vue"),
  },
  {
    name: "my_profile",
    path: "/:lang?/my_profile",
    component: () => import("../Pages/MyProfile/MyProfile.vue"),
  },
  {
    name: "not-found",
    path: "/:lang?/not-found",
    component: () => import("../../src/components/PageNotFound.vue"),
  },
  {
    name: "store_locator",
    path: "/:lang?/store_locator",
    component: () => import("../../src/Pages/StoreLocator/StoreLocator.vue"),
  },
];

const router = createRouter({
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 });
    });
  },
  history: createWebHashHistory(),

  routes,
});
router.beforeEach((to, from, next) => {
  console.log(to);

  const lang = localStorage.getItem("pref_lang") || "en";

  if (to.matched.length === 0) {
    const notFoundPath = `/${lang}/not-found`;
    next(notFoundPath);
    return;
  }
  // if (to.params.display != 'preview') {
  //   const notFoundPath = `/${lang}/not-found`;
  //   next(notFoundPath);
  //   return;
  // }

  if (!to.params.lang) {
    const redirectpathlogin = "/" + lang + to.path;
    next({ path: redirectpathlogin, query: to.query });
  } else {
    // const redirectpathlogin =  to.path;
    next();
  }
});
export default router;
