<template>
  <v-app id="app">
    <Header></Header>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer style="width: 100%" class="p-0">
      <FooterSection></FooterSection>
    </v-footer>
  </v-app>
</template>

<script>
import FooterSection from "./Pages/FooterSection";
// import HomePage from "./Pages/Home/HomePage.vue";
import Header from "./Pages/HeaderNav.vue";
// import SleekNew from "./Pages/SleekNew";

const default_layout = "default";
export default {
  components: {
    // HomePage,
    FooterSection,
    Header,
  },
  data() {
    return {
      currentTheme: localStorage.getItem("theme-type"),
      envImagePath: process.env.VUE_APP_IMAGE_PATH,
      categories: [],
      pagebuilder_links: [],
      sel_lang: "",
      selected_page: "",
      items: ["Happening Now", "Bar", "Fizz", "Buzz"],
    };
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    nextItems() {
      return this.notification_array.slice(
        this.currentIndex,
        this.currentIndex + this.itemsPerPage
      );
    },
    showLoadMoreButton() {
      return this.currentIndex < this.notification_array.length;
    },
  },

  mounted() {
    // this.scrollToTop();

    //
    // this.fetchCategories();
    if (!localStorage.getItem("pref_lang")) {
      console.log("language selected");
      console.log(localStorage.getItem("pref_lang"));
      localStorage.setItem("pref_lang", "en");
      this.sel_lang = localStorage.getItem("pref_lang");
      let newRoute = {
        name: this.$route.name,
        params: { ...this.$route.params, lang: this.sel_lang },
      };

      this.$router.push(newRoute);
    }
    // else {
    //   this.sel_lang = localStorage.getItem("pref_lang");
    //   let newRoute = {
    //     name: this.$route.name,
    //     params: { ...this.$route.params, lang: this.sel_lang },
    //   };
    //   this.$router.push(newRoute);
    // }

    //localStorage.getItem("pref_lang") || "en";
  },
  created() {
    this.emitter.on("app_image_update", () => {});
  },
  watch: {
    "$route.params.lang"(newLang) {
      if (newLang == "en" || newLang == "ar") {
        this.$i18n.locale = newLang;
        if (newLang) {
          localStorage.setItem("pref_lang", newLang);
          this.sel_lang = newLang;
        } else {
          localStorage.setItem("pref_lang", "en");
          this.sel_lang = "en";
        }
      } else {
        localStorage.setItem("pref_lang", "en");
        this.sel_lang = "en";
      }
    },
    "$route.name": {
      immediate: true,
      handler() {
        if (this.$route.name) {
          //alert("mounted");
          this.selected_page = this.$route.name;
          const descEl = document.querySelector('head meta[name="description"]');
          const titleEl = document.querySelector('head title');
          descEl.setAttribute('content', localStorage.getItem("meta_description"));
          titleEl.textContent = localStorage.getItem("meta_title");
        }
      },
    },
    
    // "localStorage.getItem('meta_description')": {
    //   immediate: true,
    //   handler() {
    //     alert("slug");
    //     if (localStorage.getItem("meta_description")) {
    //       const descEl = document.querySelector('head meta[name="description"]');
    //       const titleEl = document.querySelector('head title');
    //       descEl.setAttribute('content', localStorage.getItem("meta_description"));
    //       titleEl.textContent = localStorage.getItem("meta_title");
    //       }
    //   },
    // },
    "$route.params.display"() {
      if (this.$route.params.display == "preview") {
        sessionStorage.setItem("ispreview", "Y");
      }
      //this.fetchCategories();
    },
  },

  methods: {
    // setUserLang(lang) {

    //   if (lang == "ar") {
    //     localStorage.setItem("pref_lang", "ar");
    //     this.sel_lang = "en";
    //     this.$i18n.locale = "en";
    //     window.location.reload();
    //   } else {
    //     this.sel_lang = "ar";
    //     localStorage.setItem("pref_lang", "en");
    //     this.$i18n.locale = "ar";
    //     window.location.reload();
    //   }
    // },

    switchTheme() {
      const storedTheme = localStorage.getItem("theme-type");
      if (storedTheme === "theme-dark") {
        localStorage.setItem("theme-type", "theme-light");
        this.currentTheme = localStorage.getItem("theme-type");
      } else {
        localStorage.setItem("theme-type", "theme-dark");
        this.currentTheme = localStorage.getItem("theme-type");
      }
    },

    loadMore() {
      this.loader = true;
      this.displayedItems.push(...this.nextItems);
      this.currentIndex += this.itemsPerPage;
      if (!this.showLoadMoreButton) {
        console.log("All data loaded.");
      }
      this.loader = false;
    },
  },
};
</script>
<style>
.nav-link {
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
}

:global(.v-slide-group .v-slide-group__content) {
  margin-left: 0px !important;
}
.carousel-title {
  width: 98%;
  margin: 0px auto;
  justify-content: space-between;
  align-items: end;
  border-bottom: 1px solid #dddddd;
  margin-top: 60px;
}
.carousel-title > h3 {
  font-size: 24px;
  color: #000000;
  font-weight: 800;
  font-family: "FontAwesome";
  color: #000000;
}
.more {
  color: #000000;

  cursor: pointer;
  font-size: 19px;

  font-weight: 800;
  font-family: "FontAwesome";
}
.v-main > div {
  margin: 0px !important;
  padding: 0px;
}
/* .navbar-expand-lg .navbar-nav .dropdown-menu{left: 2px;} */
</style>

