<template>
  <v-overlay persistent v-model="loader" class="overlay_class">
    <div class="loader"><img src="../../assets/images/loader.png"></div>
  </v-overlay>
</template>
<script>
export default {
  data: () => ({
    loader: true,
  }),
};
</script>
<style scoped>
.loader,
.overlay_class {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 48%;
}

.loader {
  /* animation: rotate 1s infinite; */
  height: 50px;
  width: 50px;
  animation: zoomInOut 2s infinite; 
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  /* background-color: #cb2025;
  box-shadow: 30px 0 0 #f8b334; */
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  /* background-color: #00a096;
  box-shadow: 30px 0 0 #97bf0d; */
}

@keyframes zoomInOut {
  0%, 100% {
    transform: scale(1); 
  }
  50% {
    transform: scale(1.2); 
  }
}

/* @keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }

  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
} */

/* @keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }

  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }
} */
</style>