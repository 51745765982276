<template>
  <div class="w-100" v-bind:style="sel_lang == 'ar' ? 'direction:rtl' : ''">
    <!-- Search Dialog Start-->
    <div
    
      class="glob-search-parent"
      v-bind:style="$route.name != 'home' ? 'position:relative !important' : ''"
    >
      <div>
        <div class="search-bar">
          <form @submit.prevent="fetchSearchResults(search_data)" class="w-100">
            <v-card-text class="d-flex" style="align-items: center">
              <v-row dense>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    variant="underlined"
                    v-model="search_data"
                    :label="$t('search')"
                    append-inner-icon="mdi mdi-file-document-plus"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>&nbsp;&nbsp;
              <v-btn
                @click="fetchSearchResults(search_data)"
                color="#fff !important"
                :text="$t('search')"
                variant="tonal"
              ></v-btn>
            </v-card-text>
          </form>
        </div>       
      </div>
      
    </div>
    <div class="store-locator cursor-pointer" @click="goToStoreLocator()"
    v-bind:style="$route.name != 'home' ? 'padding: 23.2px;top: 110px;' : ''"><i class="fa fa-map-marker fa-lg" aria-hidden="true"></i>&nbsp;
 Store locator</div>
    <!-- Search Dialog End-->
  </div>
</template>

<script>
// import SleekNew from "./Pages/SleekNew";

const default_layout = "default";
export default {
  props: ["header_links"],
  components: {},
  data() {
    return {
      currentTheme: localStorage.getItem("theme-type"),
      envImagePath: process.env.VUE_APP_IMAGE_PATH,
      header_menu: [],
      sel_lang: "",
      search_data: "",
    };
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
    nextItems() {
      return this.notification_array.slice(
        this.currentIndex,
        this.currentIndex + this.itemsPerPage
      );
    },
  },

  mounted() {
    this.sel_lang = localStorage.getItem("pref_lang");
  },
  created() {
    this.emitter.on("app_image_update", () => {});
  },
  watch: {
    "$i18n.locale"(newLocale) {
      if (newLocale === "ar") {
        this.sel_lang = "ar";
      } else {
        ("");
        this.sel_lang = "en";
      }
    },
  },

  methods: {
    goToStoreLocator(){
      this.$router.push({ name: "store_locator"});
    },
    

    fetchSearchResults(data) {
      this.initval = true;
      this.$router.push({ name: "search-results", query: { slug: data } });
      this.dialog = false;
      this.search_data = "";
    },
  },
};
</script>
<style scoped>
.nav-link {
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 13px;
}
.v-card-text{padding: 0px !important;}
</style>

